<template>
    <div class="lvya-appCenter">
        <div class="appCenter-box">
            <p class="appCenter-title">无需安装APP，各种应用&nbsp;&nbsp;&nbsp;&nbsp;随需而生</p>
            <div class="app-div">
                <ul class="tab-tilte">
                    <li v-for="(item,i) in listData" :key="i" @click="cur=i">
                        <p :class="{active:cur==i}" class="appCenter-left-p">{{item.title}}</p>
                        <div class="appCenter-left-div" v-for="(list,v) in item.webAdminApps" :key="v" @click="hrefUr2(list.id)">
                            <img :src="'http://www.lvya.org/'+list.img" alt="" class="appCenter-left-img">
                            <p class="appCenter-left-p2">{{list.title}}</p>
                        </div>
                    </li>
                </ul>
                <div class="tab-content">
                    <div v-for="(item,i) in listData" :key="i" @click="cur=i"  v-show="cur==i">
                        <div class="appCenter-right-div1">
                            <img :src="'http://www.lvya.org/'+item.img" alt="">
                            <p class="appCenter-right-div1-p1">{{item.title}}</p>
                            <p class="mfaz" @click="hrefUrl1()">免费安装</p>
                        </div>
                        <div class="appCenter-right-div2">
                            <p class="appCenter-right-div2-tip">包含应用</p>
                            <ul class="appCenter-right-div2-ul">
                                <li v-for="(list,v) in item.webAdminApps" :key="v" @click="hrefUr2(list.id)">
                                    <img :src="'http://www.lvya.org/'+list.img" alt="">
                                    <p>{{list.title}}</p>
                                </li>
                            </ul>
                             <p class="appCenter-right-div2-tip">套件介绍</p>
                             <p class="appCenter-right-div2-details">
                                 {{item.remark}}
                             </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
export default {
    components: {
    },
    data() {
        return {
            cur: this.$route.query.cur ? this.$route.query.cur : '0',
            listData: []
        }
    },
    methods: {
        getList() {
            let origin = /localhost/.test(window.location.href) ? window.GateWayServer : location.origin
            console.log(origin)
            axios.post(`${origin}/api/1/suite`, qs.stringify({
                pageNo: this.currentPage,
            }))
            .then(res => {
                this.listData = res.data.list
                console.log(this.listData)
            })
        },
        hrefUrl1() {
            window.open('http://lvya.lvya.org', '_blank')
        },
        hrefUr2(id) {
            this.$router.push({ path: '/appCenterDetails', query: { id }})
        },
    },
    created() {
        this.getList()
    },
    mounted() {
    },
}
</script>

<style lang="less" scoped>
@import url("./less/style.less");
</style>
